.f-container {
	display: grid;
	display: -ms-grid;
	grid-template-columns: 0.75fr 3fr;
	-ms-grid-columns: 0.75fr 3fr;
	padding: 16px;
}
.d-card {
	/* background-color: #363A45;
    color: #FFF; */
	padding: 16px 16px 16px 16px;
	color: #fff;
	background-color: #56575a;
	text-transform: uppercase;
	/* background-color:#c9c9c9; */
}
.d-cardItem {
	/* background-color: #272B34; */
	text-align: center;
	padding: 8px;
	/* background: linear-gradient(45deg,#9a989b,#fbfbfb ); */
	background: linear-gradient(45deg, #282828, transparent);
}
.card-cap-txt {
	font-size: 0.5rem !important;
}
/* .t-h-row{
    background-color: #56575a;
} */
/* .t-h-row > th{
    color: #FFF;
} */
.td-b-r {
	border-right: 1px solid #e0e0e0;
}

.forecast-svg svg {
	width: 16px;
	height: 16px;
	cursor: pointer;
	vertical-align: middle;
	margin-left: 2px;
}

.forecast-svg svg path {
	fill: #9d9d9d;
}

.forecast-svg svg:hover path {
	fill: #737070;
}
.com-container > div {
	width: 100%;
}
@media screen and (min-width: 1441px) {
	.f-container {
		grid-template-columns: 1fr 3fr;
		-ms-grid-columns: 1fr 3fr;
	}
}
