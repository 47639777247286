.header {
	margin-bottom: 16px;
	margin-top: 20px !important;
}

.title {
	font-family: "Montserrat, Roboto, Helvetica, Arial, sans-serif";
	font-size: 18px;
	color: #00000099;
	font-weight: 600;
}

.select {
	padding: 4px;
	font-size: 14px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.container {
	display: flex;
	flex-direction: column;
	padding: 10px;
	gap: 7px;
	max-height: 68.5vh;
	overflow-y: auto;
}

.card {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	border: 1px solid #00000040;
	padding: 7px;
	border-radius: 3px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	width: 100%;
}

.icon {
	margin-right: 5px;
}

.userName {
	flex-grow: 1;
	font-size: 14px;
}

.time {
	font-size: 14px;
}

.loadingBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	height: 100%;
	min-height: 200px;
}

.errorBox {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: #d32f2f;
	text-align: center;
	font-size: 14px;
}

.noDataBox {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: #757575;
	text-align: center;
	font-size: 14px;
}

.indexBox {
	border: 1px solid #00000040;
	padding: 5px;
	font-weight: 500;
	background: #7367f0;
	color: white !important;
	min-width: 25px;
	border-radius: 3px;
}
